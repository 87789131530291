import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";
import ContractForm from '../components/contact-form';
import onkarIcon from '../images/team/onkar.jpg';
import rahulIcon from '../images/team/rahul.jpeg';
import maleIcon from '../images/team/person-male.png';
import femalIcon from '../images/team/person-female.png';
import angularIcon from '../images/icons/angular.svg';
import reactIcon from '../images/icons/react.svg';
import nodeIcon from '../images/icons/nodejs.png';
import mongodbIcon from '../images/icons/mongodb.png';

// markup
const AboutPage = () => {
  return (
    <Layout pageClass="about-page">
        <Helmet>
            <title>MatteCurve - About Us | Top Web and Mobile App Development Company In India</title>
            <meta name="description" content="MatteCurve is one of the leading javascript development company in India providing Custom Software, Web and Mobile Application Development services like node.js, meteor.js, react.js and many more services." />
            <meta name="keywords" content="Web Development, Mobile App Development, React, Node.js, Angular.js, Remote Developers" />
        </Helmet>
        <Header></Header>
        <main>
        <div className="hero-wrapper container-fluid">
            <h1 className="animate__animated animate__fadeInUp">About MatteCurve</h1>
            <br/>
            <p className="animate__animated animate__fadeInUp">
            MatteCurve is a technology and engineering consultancy for businesses. At MatteCurve, we collaborate
to deliver the best technology and engineering solutions that can scale your business by 10X.
 We help our clients build solid software applications and websites that enable them to reshape their business and grow. We offer end-to-end software development services and have clients across the globe.
            </p>
            <br/>
            <p className="animate__animated animate__fadeInUp">
                We focus on research based solutions. Our customers trust us because we are transparent and honest. We take full pride in the quality work we do and that's what makes us unique.
            </p>
        </div>
        <div className="our-team container-fluid mt-5 animate__animated animate__fadeInUp">
            <h3>Our Team</h3>
            <ul className="row col-12 container-fluid animate__animated animate__fadeInUp animate__delay-1s">
                <li className="col col-sm-6 col-md-3 mb-5 text-center">
                    <img src={onkarIcon} alt="Onkar" />
                    <h4>Onkar</h4>
                    <h5>Founder, CTO</h5>
                </li>
                <li className="col col-sm-6 col-md-3 mb-5 text-center">
                    <img src={rahulIcon} alt="Rahul" />
                    <h4>Rahul Khichar</h4>
                    <h5>React Expert (IIT)</h5>
                </li>
                <li className="col col-sm-6 col-md-3 text-center">
                    <img src={femalIcon} alt="Poonam" />
                    <h4>Poonam Saroj</h4>
                    <h5>React Expert</h5>
                </li>
                <li className="col col-sm-6 col-md-3 text-center">
                    <img src={maleIcon} alt="Mohak" />
                    <h4>Mohak</h4>
                    <h5>React, Node Expert</h5>
                </li>
                <li className="col col-sm-6 col-md-3 text-center">
                    <img src={maleIcon} alt="Aman" />
                    <h4>Aman</h4>
                    <h5>Business Director</h5>
                </li>
            </ul>
        </div>
        <div className="our-values container-fluid mt-5 animate__animated animate__fadeInUp">
            <h3>Our Values</h3>
            <ul className="row col-12 container-fluid animate__animated animate__fadeInUp animate__delay-1s">
                <li className="col col-sm-6 col-md-3 mb-5 text-center">
                    <h4>Efficiency</h4>
                </li>
                <li className="col col-sm-6 col-md-3 mb-5 text-center">
                    <h4>Robustness</h4>
                </li>
                <li className="col col-sm-6 col-md-3 text-center">
                    <h4>A+ engineers</h4>
                </li>
                <li className="col col-sm-6 col-md-3 text-center">
                    <h4>Trust</h4>
                </li>
            </ul>
        </div>
        <div className="our-expertise container-fluid mt-5 animate__animated animate__fadeInUp animate__delay-1s">
            <h3 className="text-center">Our Expertise</h3>
            <ul className="row col-12 container-fluid animate__animated animate__fadeInUp animate__delay-2s">
                <li className="col col-6 col-sm-6 col-md-3 mb-5 text-center">
                    <img src={nodeIcon} alt="Node.js" />
                    <h4>Node.js</h4>
                </li>
                <li className="col col-6 col-sm-6 col-md-3 mb-5 text-center">
                    <img src={reactIcon} alt="React" />
                    <h4>React</h4>
                </li>
                <li className="col col-6 col-sm-6 col-md-3 text-center">
                    <img src={angularIcon} alt="Angular" />
                    <h4>Angular</h4>
                </li>
                <li className="col col-6 col-sm-6 col-md-3 text-center">
                    <img src={mongodbIcon} alt="MongoDB" />
                    <h4>MongoDB</h4>
                </li>
            </ul>
            <ul className="row col-12 numbers-row container-fluid animate__animated animate__fadeInUp animate__delay-2s">
                <li className="col col-sm-4 text-center">
                    <h5>10 +</h5>
                    <h4>Years</h4>
                </li>
                <li className="col col-sm-4 text-center">
                    <h5>1000 +</h5>
                    <h4>Hours</h4>
                </li>
                <li className="col col-sm-4 text-center">
                    <h5>20 +</h5>
                    <h4>Projects</h4>
                </li>
            </ul>
        </div>
        <div className="container">
            <div className="row mt-5 contact-us-section">
                <div className="col mt-5">
                    <ContractForm></ContractForm>
                </div>
            </div>
        </div>
        </main>
        <Footer></Footer>
    </Layout>
  )
}

export default AboutPage
